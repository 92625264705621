import React from 'react';

function Home() {
    return (
<div>
<h1>Home</h1>

<div style={{ padding: '20px', backgroundColor: 'lightgray', border: '2px solid red', borderRadius: '10px', margin: '20px 0' }}>
  <p style={{ color: 'red', fontWeight: 'bold' }}>
    Notification: Uner Tan has passed away on 4th February 2022. Rest in peace.
  </p>
</div>
        <section className="App-section">
          <ul>
            <li>Honorary Member of the Turkish Academy of Sciences</li>
            <li>Professional Member of the American Academy of Neuropsychology</li>
            <li>Liaison Member of the American Psychological Association</li>
            <li>Member of the World Innovation Foundation</li>
            <li>Senior Scientist at Cukurova University, Adana, Turkey</li>
            <li>Senior Scientist at Mevlana University, Konya, Turkey</li>
          </ul>
        </section>
        
        <section className="App-section">
          <h3>Education</h3>
          <ul>
            <li>1945-1950: Primary School, Vezirkopru, Samsun, Turkey</li>
            <li>1950-1954: Middle School and Lyceum, Osmancik and Corum, Turkey</li>
            <li>1954-1956: High school, Izmir, Turkey</li>
            <li>1956-1960: Egean University, Medical School, Izmir, Turkey</li>
            <li>1960-1966: Goettingen University, Medical School, Germany</li>
            <li>1960-1966: Ph.D. Max-Planck Institute for Medical Research, Goettingen, Germany</li>
          </ul>
        </section>
        
        <section className="App-section">
          <h3>Academic activities</h3>
          <ul>
          <li>1966-1967: Ass. Prof., Max-Planck Institute for Medical Research, Goettingen, Germany</li>
    <li>1967-1969: Ass. Prof. Institute of Physiology, Goettingen University, Germany</li>
    <li>1969-1973: Lecturer, Institute of Physiology, Hacettepe University, Ankara, Turkey</li>
    <li>1973-1977: Assoc. Professor for Physiology, Medical Schools of Hacettepe (Ankara) and Ataturk (Erzurum) Universities, Turkey</li>
    <li>1977-1999: Professor of Physiology, Ataturk University, Medical School, Erzurum, Turkey</li>
    <li>1973-1999: Head of the Department of Physiology, Ataturk University, Erzurum, Turkey</li>
    <li>1999-2002: Head of the Department of Physiology, Medical School, BlackSea Technical University, Trabzon, Turkey</li>
    <li>2002-2004: Head of the Department of Neurophysiology Unit, Adana, Turkey</li>
    <li>2016-2016: Medical School, Mevlana University, Konya, Turkey</li>
    <li>2004-present: Senior scientist, Medical School, Cukurova University, Adana, Turkey</li>
          </ul>
        </section>
        
        <section className="App-section">
        <h3>Editorial Activities</h3>
          <ul>
    <li>2002-2009: Editor, International Journal of Neuroscience, USA</li>
    <li>2002-2008: Editorial board, Perceptual and Motor Skills, USA</li>
    <li>2002-present: Editor for Neurophysiology section in the Journal of Neuroquantology, Turkey</li>
    <li>2008-present: Editorial Board in the Journal of Neuroquantology, Izmir, Turkey</li>
    <li>2011: Editorial Board in “International Journal of Basic and Clinical Research”</li>
    <li>1980-present: Reviewer for various international and national scientific periodicals</li>
  </ul>
        </section>      
  
        <section className="App-section">
        <h3>Awards</h3>
  <ul>
    <li>1973: Science Award for young scientists, Turkish Scientific and Technical Council</li>
    <li>1986: Science Award, Turkish Scientific and Technical Council, Ankara, Turkey</li>
    <li>1975: Eczacibasi Medical Award, Istanbul, Turkey</li>
    <li>1980: Dorothea Morton First Prize for Neurological Sciences, Istanbul, Turkey</li>
    <li>1988: Sedat Simavi Medical Award, Istanbul, Turkey</li>
    <li>1985: Ph. D., Honoris Causa in education, USA</li>
    <li>1986: Einstein Medals for science and peace, USA</li>
    <li>1987: Medal of Honour, USA</li>
    <li>1991: Nobel Medals for Science and Peace, Albert Einstein Foundation, USA</li>
    <li>1992: Golden Academy Award for Lifelong Achievement, USA</li>
    <li>1998: Gold Record for Brain Research, American Biographical Institute, USA</li>
  </ul>
        </section>    
  
        <section className="App-section">
        <h3>Professional societies</h3>
  <ul>
    <li>Turkish Academy of Sciences</li>
    <li>American National Academy of Neuropsychology</li>
    <li>New York Academy of Sciences</li>
    <li>International Brain Research Organization</li>
    <li>European Neuroscience Association</li>
    <li>Society for Psychophysiological Research</li>
    <li>National Societies for EEG, EMG, and Neurology</li>
    <li>American Psychological Association</li>
    <li>World Innovation Foundation</li>
  </ul>
        </section>    
  
  
        <section className="App-section">
  <h3>Scientific studies</h3>
  <ul>
    <li>Physiology and pharmacology of the spinal and cerebral motor systems</li>
    <li>Epilepsy</li>
    <li>Mechanisms of the effects of the antidepressant drugs</li>
    <li>Microelectrode studies of the pyramidal neurons in cats</li>
    <li>Asymmetric brain in mice, rats, cats, and humans</li>
    <li>Primitive reflexes in human neonates</li>
    <li>Sex differences in cerebral lateralization and cognition</li>
    <li>Hand preference and hand skill in relation to sex hormones</li>
    <li>Intelligence (sex differences, hormonal relations)</li>
    <li>Finger-length patterns and sex differences to sex hormones</li>
    <li>Uner Tan Syndrome (habitual quadrupedalism + impaired cognition + cerebellovermial hypoplasia)</li>
  </ul>
        </section>    
      </div>
  );
}

export default Home;
