import React from "react";

function AboutMe() {
  return (
    <div>
      <h1>About Me</h1>

      <div className="modal-body row">
        <div className="col-md-6">
          <h2>Personal information</h2>
          <ul>
            <li>Place of birth: Unye, Ordu, Turkey</li>
            <li>Date of birth: 1 May 1937</li>
          </ul>
        </div>
        <div className="col-md-6">
          <img 
            src="uner_tan.jpg" 
            alt="Üner Tan" 
          />
        </div>
      </div>

      <h2>HOBBIES AND INTERESTS</h2>
      <ul>
        <li>
          Violin playing since 1952, first violinist in Izmir Philharmony orchestra (1956-1960) and in the Academic Orchestra of Goettingen University, Germany  (1960-1966).
        </li>
        <li>Stamp collecting</li>
        <li>Human evolution</li>
      </ul>
      <h1>Funny Video</h1>
      <div>
        <iframe 
          src="https://www.youtube.com/embed/N1fC0vKWqGk?si=kgM4h9N0gujhgwV5" 
          title="Funny Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
    </div>
  );
}

export default AboutMe;
