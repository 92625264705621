import React from 'react';

function Publications() {
  return (
    <div>
      <h1>Publications</h1>

      <section>
        <h3>Two families with quadrupedalism, mental retardation, no speech, and infantile hypotonia (Uner Tan Syndrome Type-II); a novel theory for the evolutionary emergence of human bipedalism</h3>
        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4001073/">The National Center for Biotechnology Information</a>
      </section>

      <section>
        <h3>Kinematics and ontogeny of locomotion in monkeys and human babies</h3>
        <a href="https://www.ncbi.nlm.nih.gov/pubmed/12050907">The National Center for Biotechnology Information</a>
        <p>April 2002 Zeitschrift für Morphologie und Anthropologie 83(2-3):383-400</p>
        <p>DOI: 10.2307/25757620</p>
      </section>

      <section>
        <h3>Human Quadrupeds, Primate Quadrupedalism, and Uner Tan Syndrome</h3>
        <a href="https://www.ncbi.nlm.nih.gov/pubmed/25029457">The National Center for Biotechnology Information</a>
        <a href="http://journals.plos.org/plosone/article/file?id=10.1371/journal.pone.0101758&type=printable">Download from PLOS</a>
        <a href="LIZA%20CRITIQUE%20UTS.PDF">LIZA CRITIQUE UTS.PDF</a>
      </section>

      <section>
        <h3>Uner Tan Syndrome in Neuroquantology 2005</h3>
        <a href="UNERTAN_SEND.NEUROQUANTOLOGY%20%281%29.pdf">UNERTAN_SEND.NEUROQUANTOLOGY.pdf</a>
      </section>

      <section>
        <h3>Google Scholar</h3>
        <ul>
          <li>Citations: 3886</li>
          <li>h-index: 34</li>
          <li>i10-index: 105</li>
        </ul>
        <p>! Around 250 articles on spinocerebral motor control, intelligence, spinal and cerebral lateralization, sex differences in cognition and handedness in relation to sex hormones in men and women, finger-length patterns in relation to sex hormones, and Uner Tan Syndrome.</p>
      </section>
    </div>
  );
}

export default Publications;
