import React from 'react';

function ContactMe() {
  return (
    <div>
      <h1>Contact Me</h1>

      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <h2>Prof. Dr. Uner Tan</h2>
        <p>Senior Scientist</p>
        <p>Honorary member of the Turkish Academy of Sciences, Ankara, Türkiye</p>
      </div>

      <h3 style={{ margin: '20px 0' }}>Cukurova University, Adana, Türkiye</h3>

      <ul>
        <li>unertan37 _at_ yahoo.com</li>
        <li>uner.tan37 _at_ gmail.com</li>
        <li>unertan _at_ cu.edu.tr</li>
        <br></br>
        Uner Tans's personal assistant:
        <li>mustafaipekbayrak_at_gmail.com</li>
      </ul>
    </div>
  );
}

export default ContactMe;
