import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home'; 
import AboutMe from './AboutMe'; 
import Publications from './Publications'; 
import ContactMe from './ContactMe'; 
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router>
       <Helmet>
        <title>Uner Tan - Personal Page</title>
        <meta name="description" content="Welcome to the official website of Prof. Dr. Uner Tan, a distinguished scientist and honorary member of the Turkish Academy of Sciences. Dive deep into a rich legacy encompassing groundbreaking research in spinocerebral motor control, human bipedalism, and the acclaimed Uner Tan Syndrome. With a repository of over 250 scholarly articles, the site offers a comprehensive view of Prof. Dr. Uner Tan's contributions to the fields of neuroscience, psychology, and anthropology. Explore his prolific career through a range of resources including publications, video content, and personal anecdotes. Whether you are a researcher, a student, or someone with a keen interest in the intricate workings of the human mind and body, this platform provides an enriching journey through the lens of a luminary in the scientific world." />
        <meta name="keywords" content="Uner Tan, Uner Tan Syndrome, neuroscience, neuropsychology, spinocerebral motor control, Turkish Academy of Sciences, human bipedalism, academic articles, research publications, neuroquantology, quadrupedalism, human evolution, Cukurova University, scientific studies, personal anecdotes, Primate Quadrupedalism, Prof. Dr. Uner Tan biography, scientific awards, honorary scientist, human physiology, pharmacology, pyramidal neurons, hand preference research, human cognition studies, neurophysiology" />
      </Helmet>
      <div>
        
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-me">About Me</Link></li>
            <li><Link to="/publications">Publications</Link></li>
            <li><Link to="/contact-me">Contact Me</Link></li>
          </ul>
        </nav>
        
        <div className="App">
        <header className="App-header title-center title-h1h2">
          <h1>Prof. Dr. Uner Tan</h1>
          <h2>Professor of Physiology</h2>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-me" element={<AboutMe />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/contact-me" element={<ContactMe />} />
        </Routes>
       </div>
      </div>
    </Router>
  );
}

export default App;
